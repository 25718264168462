<template>
  <div class="">
    <Container class="relative z-10 h-full pt-24 text-white pointer-events-none lg:flex lg:pt-0 lg:items-center">
      <div class="w-full">
        <AnimatedText :copy="title"
                      tag="h2"
                      :visible="visible"
                      class="max-w-2xl text-4xl leading-tight sm:text-7xl sm:leading-tight"
        />
        <AnimatedText :copy="copy"
                      :visible="visible"
                      tag="p"
                      class="max-w-md mt-8 ml-auto text-2xl leading-tight sm:text-3xl sm:leading-tight lg:mt-24"
        />
      </div>
    </Container>
    <ThreeImageSequence :trigger="trigger"
                        class="absolute top-0 h-scren"
    />
  </div>
</template>
<script setup lang="ts">
import AnimatedText from './animated-text.vue';
import { ref, watch } from 'vue';

interface type3dProps {
  title: string;
  copy: string;
  trigger: number;
}

const props = defineProps<type3dProps>();
const visible = ref(true);

watch(
  () => props.trigger,
  trigger => {
    if (trigger > 1) visible.value = false;
    else visible.value = true;
  }
);
</script>
